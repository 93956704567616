import * as React from 'react';
import * as styles from './nattypetshop.module.scss';
import WorkExample from '../../../components/WorkExample';

//Components
import Seo from '../../../components/seo';

//Images
import nattyPetShopHeaderImage from '../../../images/work-example-headers/natty-pet-shop.png';

const NattyPetShop = ({location})=>{
    return(
        <WorkExample
            pageName={'nattypetshop'}
            cardColor={'#23501d'}
            title={'Natty Pet Shop'}
            subtitle={"Squarespace site with custom code for a local pet shop"}
            imageData={nattyPetShopHeaderImage}
            imageAlt={"Natty Pet Shop"}
            links={[
                {text: "View the project", url: "https://www.nattypetshop.com/", external: true}
            ]}
            location={location}
        >

        <Seo
            title={"Natty Pet Shop"}
            description={"Squarespace site with custom code for a local pet shop"}
        />

            <h2>About the Project</h2>
            <p>Natty Pet Shop is a Brooklyn-based pet store.  
While learning UX design and web development, I helped the owner
to create a website using Squarespace.  While not the most
technically complex site, this was my first step into the world
of development.</p>

            <h2>What I did</h2>
            <ul>
                <li>Designed a brand logo for the website and promotional content</li>
                <li>Used Squarespace to create page layouts for a 7 page website</li>
                <li>Incorporated some creative custom code into the Squarespace site to add drop down navigation and product landing pages</li>
            </ul>
        </WorkExample>
    )
}

export default NattyPetShop;